import styled from "styled-components";
import userConfig from "../../../config";
import { media } from "../../utils/media";

const H2 = styled.h2`
  color: ${userConfig.primaryColor};
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  margin: 25px 50px 15px;
`;

export default H2;
